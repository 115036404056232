import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { PrivacyPolicyComponent } from "../components/privacy-policy/privacy-policy.component";
import { AuthService } from "./auth.service";
import { RealMoodleService } from "./real-moodle.service";
import { SkillService } from "./skill.service";

@Injectable({
	providedIn: "root"
})
export class GdprGuardService implements CanActivate {
	public title;
	public description;
	public checkboxtitle;
	public gdprContent;
	public USER_APP_VERSION;
	public currentVersion;
	public accepted;
	public displayCreationBtn = true;

	constructor(
		public moodleService: RealMoodleService,
		public authService: AuthService,
		public router: Router,
		public http: HttpClient,
		public skillService: SkillService,
		public modalController: ModalController
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
		return new Promise(resolve => {
			if (this.skillService.user.auth.connected) {
				if (this.skillService.user.enroled_courses_id) {
					this.moodleService
						.getUniversityGDPR(this.skillService.user.enroled_courses_id[0], this.skillService.user.auth.id)
						.subscribe((data: any) => {
							if (!data) {
								resolve(true);
								return null;
							}
							this.gdprContent = data.gdpr_content;
							this.USER_APP_VERSION = data.record;
							if (this.USER_APP_VERSION != null) {
								if (
									new Date(this.gdprContent.versionInfo.version).getTime() >
									new Date(this.USER_APP_VERSION.data).getTime()
								) {
									this.openPrivacyPolicyModal();
								}
							}
							resolve(true);
						});
				} else {
					resolve(true);
				}
			} else {
				resolve(true);
			}
		});
	}

	/**
	 * Privacy Policy Modal
	 *
	 */
	async openPrivacyPolicyModal(url?) {
		const modal = await this.modalController.create({
			component: PrivacyPolicyComponent,
			cssClass: "modal-privacypolicy-fullscreen",
			componentProps: { gdprGuard: this }
		});
		return await modal.present();
	}

	dismiss() {
		if (this.skillService.user.auth.connected) {
			this.skillService.user.auth.connected = false;
			this.skillService.user.auth.fullName = "";
			this.skillService.user.auth.username = "";
			this.skillService.user.auth.id = 0;
			this.skillService.user.auth.email = "";
			this.skillService.user.badges = new Array();
			this.skillService.user.course_assignment_id = undefined;
			this.skillService.user.enroled_courses_id = new Array();
			this.skillService.user.moodleToken = "";
			this.authService.saveUser(this.skillService.user);
			this.router.navigateByUrl("/tabs/login");
		}

		this.modalController.dismiss();
	}

	/**
	 * Modal button consent in GDPR
	 */

	consentGDPR() {
		const currentUniversitySelected = JSON.parse(localStorage.getItem("item"));
		let universityId;
		if (!currentUniversitySelected) {
			universityId = 2;
		} else {
			universityId = currentUniversitySelected.id;
		}

		let userId;
		if (this.skillService.user && this.skillService.user.auth && this.skillService.user.auth.id) {
			userId = this.skillService.user.auth.id;
		} else {
			userId = null;
		}

		// this.skillService.user.auth.id
		this.moodleService.getUniversityGDPR(universityId, userId, "update").subscribe((data: any) => {
			this.displayCreationBtn = true;
			this.modalController.dismiss();
		}),
			err => {
				this.moodleService.manageUnexpectedErrors();
			};
	}
}
